@font-face {
    font-family: "A";
    src: url("Fonts/glacialindifference-regular-webfont.woff2") format('woff2'),
    url('Fonts/glacialindifference-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.contactBox{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3%;
    margin-left: 20%;
    margin-right: 20%;
    width: 60%;
    height: fit-content;;
    animation: fadeIn 1s;
    animation-delay: 2s;
    animation-fill-mode:backwards,forwards;
}
.contactBox .headingBox{
    grid-column-start: 1;
    grid-column-end: 4;
    height: fit-content;
}
.contactBox .cont{
    height: 33vh;
}
.contactBox h1,.contactBox h2,.contactBox p{
    font-family: "A";
    font-weight: normal;
    margin: 0;
}
.contactBox h1{
    text-transform: uppercase;
    width: fit-content;
    font-size: 4vw;
    padding-top: 1%;
}
.contactBox h2{
    text-transform: uppercase;
    font-size: 2vw;
}
.contactBox p{
    font-size: 1.5vw;
}
/*MediaBox Styles*/
.media{
    height: 100%;
    width: 100%;
    justify-content: center;
    display: block;
    z-index: 10;
    transition: 1s;
}
.media:hover{
    background-color: rgba(255, 255, 255, .1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}
.media .headingContainer h2{
    background: rgb(242,75,38);
    background: linear-gradient(90deg, rgb(208, 66, 0) 0%, rgba(242,154,38,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.media .headingContainer{
    padding-top: 3%;
    margin-bottom: 5%;
    text-align: center;
    width: 100%;
    height: fit-content;
}
.media .imageContainer{
    justify-content: center;
    text-align: center;
    width: 100%;
    height: fit-content;
}
.media .infoContainer{
    padding-top: 3%;
    text-align: center;
    width: 100%;
    height: fit-content;
}
.contIMG{
    width: 8vw;
}
.backText{
    z-index: -10;
    position: absolute;
    bottom:10vh;
    margin-left: 5vw;
    transition: 0.5s;
    animation: fadeIn 1.5s;
    animation-delay: 0.1s;
    animation-fill-mode:backwards,forwards;
}
.backText h1, .backText h3{
    font-family: "A";
    text-transform: uppercase;
    font-weight: normal;
    margin: 0;
    opacity: 0.1;
}
.backText h1{
    font-size: 20vw;
}
.backText h3{
    font-size: 6vw;
}
@keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
}
@media only screen and (max-width: 600px) {
    .contactBox{
        grid-template-columns: 1fr 1fr;
    }
    .contactBox .headingBox{
        grid-column-start: 1;
        grid-column-end: 3;
    }
    #first{
        margin-top: 10%;
    }
    .contactBox #last{
        margin-bottom: 20%;
    }
    .contactBox{
        margin-top: 0;
        margin-left: 5%;
        margin-right: 5%;
        width: 90%;
    }
    .media{
        height: max-content;
    }
    .media .headingContainer{
        margin-bottom: 5%;
        text-align: center;
        width: 100%;
        height: fit-content;
    }
    .media .imageContainer{
        justify-content: center;
        text-align: center;
        width: 100%;
        height: fit-content;
    }
    .media .infoContainer{
        padding-top: 3%;
        text-align: center;
        width: 100%;
        height: fit-content;
    }
    .media:hover{
        background-color: transparent;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
    .contactBox h1{
        text-align: center;
        width: 100%;
        font-size: 10vw;
        padding-top: 1%;
    }
    .contactBox h2{
        font-size: 6vw;
    }
    .contactBox p{
        font-size: 3.5vw;
    }
    .contIMG{
        width: 20vw;
    }
    .contactBox .cont{
        height: min-content;
    }
}