:root {
    --pad: 5%;
    --pad-start: 15%;
}
@font-face {
    font-family: "A";
    src: url("Fonts/glacialindifference-regular-webfont.woff2") format('woff2'),
    url('Fonts/glacialindifference-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "B";
    src: url("Fonts/Creattion Demo.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
body {
    background-color:#000;
    color:#fff;
    margin: 0;
    padding: 0;
}
::-webkit-scrollbar {
    display: none;
}
* {
    color: #ffffff;
    text-decoration: none;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}
section{
    width: 100%;
    padding-top: 0;
}
.hero-banner{
    text-align: center;
    position: absolute;
    justify-content: center;
    z-index: 10;
    float: bottom;
    bottom: 0;
    padding-bottom: var(--pad);
}
.hero-banner p{
    font-family: "A";
    font-weight: normal;
    font-size: 4vw;
    margin-top:-1vw;
    margin-bottom: 0;
}
.hero-banner h1{
    font-family: "B";
    font-size: 10vw;
    font-weight: normal;
    margin-top:-2vw;
    margin-bottom: 0;
    background: rgb(242,75,38);
    background: linear-gradient(90deg, rgb(208, 66, 0) 0%, rgba(242,154,38,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.skills{
    text-align: center;
    position: absolute;
    z-index: 1;
    height: 90%;
    width: 100%;
    margin-top:5%;
}
.skills p{
    font-family: "A";
    font-weight: normal;
    font-size: 8vw;
    text-transform: uppercase;
    margin-top: -1vw;
    margin-bottom: 0;
}
#skill1, #skill2, #skill3{
    z-index: -13;
    position: relative;
}
#skill4, #skill5, #skill6{
    z-index: 1;
    position: relative;
}
.hero{
    z-index: 0;
    position: absolute;
    height: 100%;
    margin: 0;
    padding: 0;
}
.image{
    display:flex;
    justify-content: center;
    width: 100%;
    bottom: 0;
}
.skill-individual {
    height: 16.666%;
    opacity: 0.15;
    transition: opacity 1s;
}
.skill-individual:hover{
    opacity: 0.5;
}
/*Animation on load*/
@keyframes fadeScroll-Down {
    0% {opacity:0;
        padding-bottom: var(--pad-start);
    }
    100% {opacity:1;
        padding-bottom: var(--pad);
    }
}
@keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
}
@keyframes fadeIn-skill{
    0% {opacity:0;padding-bottom:30%;}
    40% {opacity: 0.6;}
    100% {opacity: 0.15;}
}
.hero{
    animation: fadeIn;
    animation-fill-mode:backwards,forwards;
    animation-duration: 2s;
    animation-delay: 5.5s;
}
#skill1{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards,forwards;
    animation-delay: 1s;
}
#skill2{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards,forwards;
    animation-delay: 1.5s;
}
#skill3{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards;
    animation-delay: 2s;
}
#skill4{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards;
    animation-delay: 2.5s;
}
#skill5{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards;
    animation-delay: 3s;
}
#skill6{
    animation: fadeIn-skill 2s;
    animation-fill-mode:backwards;
    animation-delay: 3.5s;
}
.hero-banner{
    animation: fadeScroll-Down;
    animation-duration: 2s;
    animation-fill-mode:backwards,forwards;
    animation-delay: 4.5s;
}
/*Media Queries*/
@media only screen and (max-width: 600px) {
    body{
    }
    :root {
        --pad: 20%;
        --pad-start: 45%;
    }
    .hero-banner h1 {
        margin-top: -10vw;
    }
    .skills{
        margin-top: 5vw;
    }
    .skills p{
        font-size: 13vw;
        line-height: 14vw;
        margin-top: 6vw;
    }
    .skill-individual {
        height: auto;
    }
    .hero-banner{
        padding-bottom: var(--pad);
        width: 100%;
    }
    .hero{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .hero-banner h1 {
        font-size: 25vw;
    }
    .hero-banner p {
        font-size: 8vw;
    }
    #navElements{
        max-height: 0;
        opacity: 0;
    }
}
@media only screen and (max-width: 600px) and (orientation: landscape){
    .skills p {
        font-size: 10vw;
    }
}