body {
    background-color:#000;
    color:#fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
section{
    width:100vw;
    height:100vh;
}
@keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
}
footer{
    animation: fadeIn;
    animation-fill-mode:backwards,forwards;
    animation-duration: 2s;
    animation-delay: 4s;
}