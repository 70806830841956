@font-face {
    font-family: "A";
    src: url("Fonts/glacialindifference-regular-webfont.woff2") format('woff2'),
    url('Fonts/glacialindifference-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "C";
    src: url("Fonts/OpenSans-Light.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

body{
    height: auto;
}
section{
    padding-top:2%;
    height: auto;
}
.Main{

}
.ContentBox{
    margin: 4% 15%;
}
.ContentBox .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
}
.ContentBox .floatBox{
    width: 100%;
}
.ContentBox img{
    width: 100%;
    object-fit: cover;
}
.ContentBox .Main{
    width: 100%;
    height: 80vh;
    background-color: rgba(255, 255, 255, 0.05);
}
.ContentBox .Main .flyer{
    z-index: 98;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    position: absolute;
}
.ContentBox .Main .grid{
    position:relative;
    width: 80%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.05);
}
#photography a{
    transition: 0.3s ease-in;
}
#photography a:hover{
    color: rgb(242, 120, 38);
}
#uow h3{
    background: rgb(141, 0, 226);
    background: linear-gradient(90deg,  rgb(141, 0, 226) 60%, rgb(0, 6, 93) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#sbc h3{
    background: rgb(14, 107, 10);
    background: linear-gradient(90deg,  rgb(14, 107, 10) 70%, rgb(255, 255, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ContentBox .Main .grid .floatBox .text{
    width: 100%;
    height: 100%;
}
.ContentBox .Main .grid .floatBox .image{
    flex: 1;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.ContentBox .flyer .Content h1{
    opacity: 0.1;
    width: auto;
    line-height: 80%;
    font-family: "A";
    font-weight: normal;
    font-size: 16vw;
    text-transform: uppercase;
    padding:0;
    margin-top: 10%;
    margin-bottom: 0;
}
.ContentBox .flyer .Content h3{
    padding-left: 1%;
    padding-right: 1%;
    opacity: 0.5;
    width: auto;
    font-family: "A";
    font-weight: normal;
    font-size: 6vw;
    text-transform: uppercase;
    margin-top: -2vw;
    margin-left:0;
    margin-right:0;
    margin-bottom: 0;
    background: rgb(242,75,38);
    background: linear-gradient(90deg, rgb(242,75,38) 0%, rgb(242, 154, 38) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.ContentBox .text {
    font-family: "A";
    font-style: normal;
}
.ContentBox .text h1, .ContentBox .text h3{
    padding: 0 1%;
    text-transform: uppercase;
    font-weight: lighter;
    margin: 0;
}
#photography h1{
    font-size: 4vw;
}
.ContentBox .text h1{
    padding-top: 2%;
    line-height: 90%;
    font-size: 5vw;
}
.ContentBox .text h3{
    line-height: 80%;
    font-size: 3vw;
}
.ContentBox .Content p,.ContentBox .Content li,.ContentBox .Content h6{
    font-weight: lighter;
    font-family: "A";
    padding: 0 1%;
    font-size: 1.2vw;
    margin: 0;
}
.ContentBox .Content h6 {
    padding-top: 1.5%;
    text-align: left;
}
.ContentBox .Content ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1%;
    margin-block-end: 1%;
}
.ContentBox code {
    width: 100%;
    text-align: center;
}
.ContentBox .linkBox{
    width: 100%;
    justify-content: center;
}
.ContentBox a{
    width: 94%;
    position: relative;
    justify-content: center;
    text-align: center;
    display:inline-block;
    padding-top: 1%;
    margin-left: 3%;
    margin-right: 3%;
    font-size: 1.2vw;
    font-weight: normal;
}
/*Education Page Styles*/
.Education .ContentBox .text h1{
    font-size: 4vw;
}
.Education .ContentBox .text h3{
    font-size: 2.5vw;
}
@keyframes blur {
    0%{
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
    100%{
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }
}
@media only screen and (max-width: 600px) {
    .ContentBox{
        margin: 3% 5%;
    }
    .ContentBox .Content h1{
        font-size: 13vw;
        background:none;

    }
    .ContentBox .Content h3{
        font-size: 8vw;
        background: rgb(242,75,38);
        background: linear-gradient(90deg, rgb(242,75,38) 0%, rgb(242, 154, 38) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .grid{
        background: transparent;
    }
    .ContentBox .Content p{
        text-align: center;
        font-size: 5vw;
        font-weight: normal;
    }
    .ContentBox .Content h6{
        font-size: 5vw;
        font-weight: normal;
        padding-top: 3%;
        margin-bottom: -2vw;
        text-align: left;
    }
    .Education .ContentBox .text h1{
        font-size: 13vw;
    }
    .Education .ContentBox .text h3{
        font-size: 8vw;
    }
    .ContentBox .Content li{
        font-size: 5vw;
        font-weight: normal;
    }
    .ContentBox a{
        justify-content: center;
        text-align: center;
        margin: 0 3%;
        font-size: 5vw;
        font-weight: normal;
    }
    .ContentBox .Main{
        background-color: transparent;
        height: fit-content;
    }
    .ContentBox .Main .grid{
        width: 100%;
    }
    .ContentBox .grid{
        display: block;
        justify-content: center;
        margin-bottom: 20%;
        padding-bottom: 5%;
    }
    .ContentBox .Main .grid{
        margin-top: 0;
        margin-bottom: 6%;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
    }
    .flyer{
        display: none;
    }
    .img{
        display: block;
    }
    .ContentBox .text h1, .ContentBox .text h3{
        text-align: center;
    }
    #photography h1{
        font-size: 12.7vw;
    }
    #first .grid{
        margin-top: 25px;
    }
    #last .grid{
        margin-bottom: 22%;
    }
    section {
        height: auto;
    }

}