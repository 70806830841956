@font-face {
    font-family: "A";
    src: url("Fonts/glacialindifference-regular-webfont.woff2") format('woff2'),
    url('Fonts/glacialindifference-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.under{
    justify-content:space-between ;
    margin-left: 3%;
    margin-right: 3%;
}
.under h1{
    width: fit-content;
    padding-top: 4%;
    margin: 0;
    font-size: 6vw;
    animation: fadeIn 1s;
    animation-fill-mode:backwards,forwards;
    text-transform: uppercase;
    font-weight: normal;
    font-family: "A" ;
    background: rgb(242,75,38);
    background: linear-gradient(90deg, rgb(255, 84, 0) 0%, rgb(242, 154, 38) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.under p{
    margin:0;
    font-size: 1.7vw;
    animation: fadeIn 1s;
    animation-fill-mode:backwards,forwards;
    animation-delay: 0.25s;
    text-transform: uppercase;
    font-weight: normal;
    font-family: "A" ;
}
code{
    font-size: 1.7vw;
    text-align: left;
    font-family: Consolas, monospace ;
    text-transform: none;
}
.codeBlock{
    display: flex;
    padding-top: 5%;
    width: 100%;
    justify-content: center;
}
@keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
}
footer{
    animation: fadeIn;
    animation-fill-mode:backwards,forwards;
    animation-duration: 2s;
    animation-delay: 1s;
}
@media only screen and (max-width: 600px) {
    .under{
        padding-right: 0;
    }
    .under h1{
        padding-top: 40%;
        font-size: 13vw;
    }
    .under p{
        font-size: 4.8vw;
    }
    code{
        font-size: 4vw;
    }
}