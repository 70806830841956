footer{
    z-index: 9999;
    position: fixed;
    margin-bottom: 1vw;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    color: #FFFFFF;
}
footer .footer-logo-set{
    justify-content: space-between;
    display: inline-block;
}
footer .footer-logo-set .footer-logo{
    display: inline-block;
    margin-left: 2vw;
    margin-right: 2vw;
}
.footer-images{
    width: 1.5vw;
    transition: 0.3s ease-in;
}
.footer-images:hover{
    filter: drop-shadow(0px 0px 10px #ff761c);
}
@media only screen and (max-width: 600px) {
    footer{
        margin-bottom: 10vw;
    }
    footer .footer-logo-set .footer-logo{
        margin-left: 4vw;
        margin-right: 4vw;
    }
    .footer-images{
        width: 7vw;
    }
    .footer-images:hover{
        filter: none;
    }
}