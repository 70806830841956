body {
    background-color:#000;
    color:#fff;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.scrollNav{
    width: 100%;
    height:100%;
    padding-top: 40%;
    padding-bottom: 40%;
    text-align: center;
    justify-content: center;
}
/*Animation on load for all other pages, except Home page*/
@keyframes fadeIn{
    0% {opacity:0;}
    100% {opacity:1;}
}
header{
    animation: fadeIn;
    animation-fill-mode:backwards,forwards;
    animation-duration: 2s;
    animation-delay: 1s;
}